/* FOOTER SECTION */
.footer-section {
  padding: 5em 0;
  padding-bottom: 0;
}

.footer-section-top {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.footer-address-section {
  width: 100%;
}

.footer-address-section ul {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.footer-address-section ul li {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.footer-address-section ul li span {
  font-size: 0.9em;
}

.footer-address-section ul li span:first-child {
  flex: 0.2;
  text-transform: uppercase;
  color: var(--blue-1);
  font-weight: 600;
}

.footer-address-section ul li span:last-child {
  flex: 0.8;
  font-size: 0.9em;
}

.footer-address-section ul li span:last-child::first-letter {
  text-transform: uppercase;
}

.footer-newsletter-section {
  width: 100%;
}

.newsletter-form {
  margin-bottom: 2em;
}

.newsletter-form h4 {
  text-transform: uppercase;
  color: var(--blue-1);
}

.newsletter-form form {
  display: flex;
  gap: 1em;
  margin-top: 1em;
}

.newsletter-form form input {
  flex: 0.6;
  border: 0.1em solid var(--gray-1);
  padding: 1em;
  border-radius: 0.25em;
}

.newsletter-form form button {
  flex: 0.4;
  background: var(--red-1);
  border: none;
  color: var(--white);
  font-weight: 500;
  text-transform: capitalize;
  font-size: 1.01em;
  border-radius: 0.25em;
}

.social-platforms h4 {
  text-transform: uppercase;
  color: var(--blue-1);
}

.social-items {
  display: flex;
  gap: 1em;
  margin-top: 1em;
  list-style: none;
}

.social-item a {
  color: var(--dark-blue-1);
  text-decoration: none;
  font-size: 1.3em;
}

.footer-section-bottom {
  background: var(--red-1);
  margin-top: 2em;
  padding: 1em 0;
}

.footer-section-bottom p {
  text-transform: capitalize;
  color: var(--white);
  font-weight: 300;
  font-size: 0.9em;
  text-align: center;
}

/* MEDIA QUERY */
@media screen and (min-width: 991px) {
  /* FOOTER SECTION */
  .footer-section-top {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0;
  }

  .footer-address-section {
    width: 40%;
  }

  .footer-address-section ul li {
    flex-direction: row;
    align-items: center;
    gap: 1em;
  }

  .footer-newsletter-section {
    width: 35%;
  }

  .footer-section-bottom p {
    font-size: 1em;
    text-align: left;
  }
}
